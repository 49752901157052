import { IMenuServiceResponse, getWeekMenu } from "../menuService";
import React, { useEffect, useState } from "react";

import LikeButton from "../LikeButton";
import Restaurant from "./Restaurant";
import { formattedDays } from "../dateUtils";

const toStupidToSolveWithMath = (dayIndex: number) => {
  return dayIndex === 0 ? 6 : dayIndex - 1;
};

const Menu = () => {
  const [menu, setMenu] = useState<IMenuServiceResponse>();
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => setShowSkeleton(true), 250);

    if (menu) {
      setShowSkeleton(false);
    }

    return () => clearTimeout(loadingTimeout);
  }, [menu]);

  useEffect(() => {
    const getMenu = async () => {
      const data = await getWeekMenu();
      setMenu(data);
      window.location.hash = "202163";
    };

    getMenu();
  }, []);

  useEffect(() => {
    document.title = menu ? `Arvika Lunch v. ${menu.week}` : "Arvika Lunch";
  }, [menu]);

  useEffect(() => {
    if (!menu) return;

    const day = toStupidToSolveWithMath(new Date().getDay());

    window.location.hash = `#${menu.year}${menu.week}${day}`;
  }, [menu]);

  const title = `Lunch Arvika vecka ${menu ? menu.week : ""}`;

  const renderMenu = () => {
    if (!menu && !showSkeleton) return;

    if (!menu) return renderSkeleton();

    const days = formattedDays(menu.year, menu.week);

    return days.map((dayLabel, dayIndex) => {
      return (
        <div key={`day-${dayIndex}`} className="mt-4 mb-8 last:mb-0">
          <div className="mb-1 pl-4 text-menu-text font-semibold text-sm">
            <a id={`${menu.year}${menu.week}${dayIndex}`}>{dayLabel}</a>
          </div>
          <div className="bg-menu-fg px-4 pb-4 rounded flex flex-col shadow">
            {menu.restaurants.map((restaurant, restaurantIndex) => (
              <Restaurant
                key={`r-${restaurantIndex}-${dayIndex}`}
                url={restaurant.url}
                name={restaurant.name}
                text={restaurant.menu[dayIndex].text}
              />
            ))}
          </div>
        </div>
      );
    });
  };

  const renderSkeleton = () => {
    return (
      <div className="mt-4 mb-8 last:mb-0">
        <div className="bg-menu-fg px-4 pb-4 rounded flex flex-col shadow">
          <div className="pt-4 pb-4 last:pb-0 border-menu-bg border-b-2 last:border-0">
            <div className="animate-pulse bg-menu-bg-light h-4 rounded"></div>
            <div className="animate-pulse w-2/4 mt-2 bg-menu-bg-light h-4 rounded"></div>
            <div className="animate-pulse w-1/4 mt-2 bg-menu-bg-light h-4 rounded"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="md:hidden flex justify-center items-center">
        <a href="https://www.facebook.com/arvikalunch.se">
          <svg
            className="h-12 w-12 pb-2"
            xmlns="http://www.w3.org/2000/svg"
            clipRule="evenodd"
            fillRule="evenodd"
            viewBox="0 0 560 400"
          >
            <path
              d="m410.096 200.048c0-71.818-58.23-130.048-130.048-130.048s-130.048 58.23-130.048 130.048c0 64.905 47.55 118.709 109.73 128.476v-90.875h-33.029v-37.601h33.029v-28.658c0-32.59 19.422-50.604 49.122-50.604 14.228 0 29.115 2.542 29.115 2.542v32.005h-16.405c-16.148 0-21.196 10.022-21.196 20.318v24.396h36.064l-5.761 37.601h-30.304v90.875c62.18-9.749 109.73-63.553 109.73-128.476z"
              fill="#1977f3"
            />
            <path
              d="m330.67 237.648 5.761-37.601h-36.064v-24.396c0-10.278 5.029-20.318 21.196-20.318h16.405v-32.005s-14.886-2.542-29.115-2.542c-29.7 0-49.122 17.996-49.122 50.604v28.658h-33.029v37.601h33.029v90.875c6.62 1.041 13.405 1.572 20.318 1.572s13.698-.549 20.318-1.572v-90.875h30.304z"
              fill="#fefefe"
            />
          </svg>
        </a>
        <LikeButton size="small" />
      </div>
      <header className="md:text-2xl mt-1 md:mt-0 text-xl">
        <div>
          {title}
          <span role="img" aria-label="food" className="pl-1">
            🍽 😋
          </span>
        </div>
      </header>
      <div className="flex">
        <div className="md:w-menu-lg w-full">{renderMenu()}</div>
        <div
          className="hidden md:block mt-10 ml-4"
          style={{ color: "#4080FF" }}
        >
          <LikeButton size="large" />
          <a
            href="https://www.facebook.com/arvikalunch.se"
            className="flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              clipRule="evenodd"
              className="w-16 h-16"
              fillRule="evenodd"
              viewBox="0 0 560 400"
            >
              <path
                d="m410.096 200.048c0-71.818-58.23-130.048-130.048-130.048s-130.048 58.23-130.048 130.048c0 64.905 47.55 118.709 109.73 128.476v-90.875h-33.029v-37.601h33.029v-28.658c0-32.59 19.422-50.604 49.122-50.604 14.228 0 29.115 2.542 29.115 2.542v32.005h-16.405c-16.148 0-21.196 10.022-21.196 20.318v24.396h36.064l-5.761 37.601h-30.304v90.875c62.18-9.749 109.73-63.553 109.73-128.476z"
                fill="#1977f3"
              />
              <path
                d="m330.67 237.648 5.761-37.601h-36.064v-24.396c0-10.278 5.029-20.318 21.196-20.318h16.405v-32.005s-14.886-2.542-29.115-2.542c-29.7 0-49.122 17.996-49.122 50.604v28.658h-33.029v37.601h33.029v90.875c6.62 1.041 13.405 1.572 20.318 1.572s13.698-.549 20.318-1.572v-90.875h30.304z"
                fill="#fefefe"
              />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default Menu;
