import "./index.css";

import Menu from "./Menu/Menu";
import React from "react";

const App = () => {
  return (
    <div className="p-4 flex flex-col items-center">
      <Menu />
      <div className="mt-1 text-menu-text">
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="flex items-center mt-2 text-sm"
          href="mailto:hej@arvikalunch.se"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <span className="ml-1">hej@arvikalunch.se</span>
        </a>
      </div>
    </div>
  );
};

export default App;
