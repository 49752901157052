import React, { FC } from "react";

interface RestaurantProps {
  name: string;
  url: string;
  text: string;
}

const Restaurant: FC<RestaurantProps> = ({ name, url, text }) => {
  return (
    <div className="pt-4 pb-4 last:pb-0 border-menu-bg border-b-2 last:border-0">
      <div className="mb-1 hover:text-gray-600 text-sm font-semibold">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          className="flex items-center"
        >
          <span>{name}</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 ml-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>
      <div className="whitespace-pre-line">{text}</div>
    </div>
  );
};

export default Restaurant;
