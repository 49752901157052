const dayNames = ["Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag"];
const monthNames = [
  "jan",
  "feb",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "aug",
  "sept",
  "okt",
  "nov",
  "dec",
];

// Gets us the first date for a specific week
const dateOfISOWeek = (year: number, week: number) => {
  const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const dow = simple.getDay();
  let isoWeekStart = simple;

  if (dow <= 4) {
    isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  console.log("date", isoWeekStart);
  return isoWeekStart;
};

// Returns an array of formatted days - looks something like `Måndag den 1 jan`
export const formattedDays = (year: number, week: number) => {
  const date = dateOfISOWeek(year, week);
  let days = [];

  for (let index = 0; index < 5; index++) {
    days.push(
      `${dayNames[index]} den ${date.getDate()} ${monthNames[date.getMonth()]}`
    );
    date.setDate(date.getDate() + 1);
  }

  return days;
};
