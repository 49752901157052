import React from "react";
const url = "https://arvikalunch.se";

const large = (
  <div
    className="fb-like"
    data-href={url}
    data-width=""
    data-layout="box_count"
    data-action="like"
    data-size="large"
    data-share="true"
  ></div>
);

const small = (
  <div
    className="fb-like"
    data-href={url}
    data-width=""
    data-layout="button_count"
    data-action="like"
    data-size="small"
    data-share="true"
  ></div>
);

interface Props {
  size: size;
}

type size = "large" | "small";

function LikeButton(props: Props) {
  return props.size === "small" ? small : large;
}

export default LikeButton;
