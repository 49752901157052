import { logtail } from ".";

const serviceUrl = process.env.REACT_APP_MENU_SERVICE_URL || "";

export interface IMenu {
  text: string;
}

export interface IRestaurant {
  name: string;
  url: string;
  menu: ReadonlyArray<IMenu>;
}

export interface IMenuServiceResponse {
  year: number;
  week: number;
  restaurants: ReadonlyArray<IRestaurant>;
}

export const getWeekMenu = (): Promise<IMenuServiceResponse> => {
  logtail.info(`Requesting service ${serviceUrl}/api`);

  return fetch(`${serviceUrl}/api`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("Json response", data);
      logtail.log(`Json response ${data}`);
      return data;
    })
    .catch((error) => {
      logtail.error(`Error when calling service at ${serviceUrl} ${error}`);
      console.error(`Error when calling service at ${serviceUrl} ${error}`);
    });
};
